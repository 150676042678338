import React from "react";

import "./landergeneral.scss";
import { Storebutton } from "./../../components/storebutton/storebutton";
import { FooterTech } from './../../pieces/footertech/footertech';
import { textfills  } from  "./../../content/textfills-lander";

export function GenLander() { return (  
  <div className="landergen">
    <div className="landergen--top">
      <div className="landergen--logo-color">
        <img src={require("./../../images/mrright-logo-horiz-color.png")} alt="" className="landergen--logo-color--img"/>
      </div>
      <div class="landergen--format">
        <div class="landergen--slogan">
          {textfills.slogan}
        </div>
        <div className="landergen--slogan--store-button">
            <Storebutton />
          </div>
        <div className="landergen--phone-hand">
          <img src={require("./../../images/phone_hand-02.png")} alt="" className="landergen--phone-hand--img"/>
        </div>
      </div>
    </div>

    <div className="landergen-middle">
      <div className="landergen-middle--slogan-header01">
        {textfills.algoheader}
        <div className="landergen-middle--slogan-sentences01">
          {textfills.algodescription}
        </div>
        <img src={require("./../../images/female-circle.png")} alt="" className="landergen--female-circle--img"/>
      </div>
      <div className="landergen-middle--slogan-header02">
        {textfills.maximizeappeal}
        <div className="landergen-middle--slogan-sentences02">
          {textfills.maximizeappeal_description}
        </div>
        <img src={require("./../../images/match-sample.png")} alt="" className="landergen--match-sample--img"/>
      </div>
      
      <div className="landergen-middle--download">
        Download it now!
        <div className="landergen-middle--download--button">
          <Storebutton />
        </div>
      </div>
    </div>

    <div className="landergen-bottom">
      <div className="landergen-bottom--sales-pitch">
        {textfills.stackodds01}<br/>
        {textfills.stackodds02}
      </div>
      <div className="landergen-bottom--sales-header01">
        {textfills.stackhard}
        <div className="landergen-bottom--sales-sentences01">
          {textfills.superficialconnect}
        </div>
        <img src={require("./../../images/refined-searches.png")} alt="" className="landergen--refined-searches--img"/>
      </div>
      <div className="landergen-bottom--sales-header02">
        {textfills.findfastslogan}
        <div className="landergen-bottom--sales-sentences02">
          {textfills.findfastquality}
        </div>
      </div>
      <img src={require("./../../images/bottom-phone.png")} alt="" className="landergen--bottom-phone--img"/>
    </div>

    <div className="landergen-footer">
      <div className="landergen-footer--storeinfo">
        {textfills.downloadfindmatch}
        <div className="landergen-footer--storeinfo--button">
          <Storebutton />
        </div>
      </div>
      <FooterTech />
    </div>
  </div>
)}