export const textfills = {
  slogan: "Matchmaking Technology That Upgrades Your Desirability",
  algoheader: "Next Level Matchmaking Tech",
  algodescription:`Mr. Right’s algorithms find real, high-quality matches in your area and then verify their accuracy in real-time, so you always know who you’re meeting, before the first date.`,
  maximizeappeal: "That Maximizes Your Appeal",
  maximizeappeal_description: 
  `Our professional team brings decades of dating coaching and expertise to the profile creation process. The result is you look as desirable as possible to your dream match.`,
  stackodds01:`No One Can Guarantee Love... But,`,
  stackodds02:`Mr. Right Stacks the Odds in Your Favor`,
  stackhard:`Dating Doesn’t Have to Be Hard`,
  superficialconnect:`In dating apps loaded with fake profiles and superficial “swipers”— connection is impossible. Mr. Right connects you with matches based on deeper criteria that leads to fun, stimulating dates.`,
  searchlogo:`REFINED
  SEARCHES`,
  findfastslogan:`How to Find the One, Fast`,
  findfastquality:`The more high-quality matches you connect with, the more likely one of them will be totally right for you. Our team helps you find your dream match, AND then coaches you to successful commitment.`,
  downloadfindmatch:`Download Mr. Right Today and Find Your Match`

};