import React from "react";

import "./landermen.scss";
import { Storebutton } from "./../../components/storebutton/storebutton";
import { FooterTech } from './../../pieces/footertech/footertech';
import { textfillsmen  } from  "./../../content/textfills-lander-men";

export function MaleLander() { return (
    <div className="men">
      <div className="logo-white">
        <img src={require("./../../images/mrright-logo-horiz-white.png")} alt="" className="logo-white--img"/>
      </div>
      <div class="men--format">
        <div class="men--slogan">
          {textfillsmen.behers}
        </div>
        <div className="men--slogan--store-button">
          <Storebutton />
        </div>
        <div className="men-phone-hand">
          <img src={require("./../../images/phone_hand-02.png")} alt="" className="men-phone-hand--img"/>
        </div>
      </div>

      <div className="men-middle">
        <div className="men-middle--slogan-header01">
          {textfillsmen.slogan_H01}
          <div className="men-middle--slogan-sentences01">
            {textfillsmen.slogan_T01}
          </div>
          <img src={require("./../../images/match-sample.png")} alt="" className="men-match-sample--img"/>
        </div>
        <div className="men-middle--slogan-header02">
          {textfillsmen.slogan_H02}
          <div className="men-middle--slogan-sentences02">
            {textfillsmen.slogan_T02}
          </div>
          <img src={require("./../../images/conversation.png")} alt="" className="men-conversation--img"/>
          <div className="men-middle--download">
            Download it now!
            <div className="men-middle--download--button">
              <Storebutton />
            </div>
          </div>
        </div>
      </div>

      <div className="men-bottom">
        <div className="men-bottom--sales-pitch">
          {textfillsmen.salesPitch}
        </div>
        <div className="men-bottom--sales-header01">
          {textfillsmen.pitch_H01}
          <div className="men-bottom--sales-sentences01">
            {textfillsmen.pitch_T01}
          </div>
          <img src={require("./../../images/match-ring.png")} alt="" className="men-match-ring--img"/>
        </div>
        <div className="men-bottom--sales-header02">
          {textfillsmen.pitch_H02}
          <div className="men-bottom--sales-sentences02">
            {textfillsmen.pitch_T02}
          </div>
        </div>
        <img src={require("./../../images/bottom-phone.png")} alt="" className="men-bottom-phone--img"/>
      </div>

      <div className="men-footer">
        <div className="men-footer--storeinfo">
          {textfillsmen.footer}
          <div className="men-footer--storeinfo--button">
            <Storebutton />
          </div>
        </div>
        <FooterTech />
      </div>

    </div>
      
)}