export const textfillsmen = {
    behers: "Be Her Mr. Right with Matchmaking Technology that Upgrades Your Desirability",

    slogan_H01: "Look Good with ZERO Work",
    slogan_T01: "Most apps force you to create a profile, and let’s face it, you’re not an expert. Plus it’s work. Our team creates your profile FOR YOU using your FB account, making you look amazing to her, effortlessly.",

    slogan_H02: "No Swiping, Just Messages from Girls",
    slogan_T02: "Swiping is so last year. It takes way too much work. With Mr. Right, we’ve eliminated swiping… instead, women are incentivized to message you FIRST. No chasing, no work…just an inbox full of messages from women well-matched to YOU.",

    salesPitch: "More Women Than Guys Is Always a Good Thing, Right?",

    pitch_H01: "The Best Girl to Guy Ratio",
    pitch_T01: "Most apps have way more guys than girls, which means any beautiful women gets hundreds of messages she can’t answer. Mr. Right’s proprietary way of enrolling more women means the best possible ratio for YOU.",

    pitch_H02: "Pro Coaching and Dating Advice",
    pitch_T02: "As if a done-for-you profile, advanced matchmaking tech, and and an amazing girl-to-guy ratio wasn’t enough… Mr.Right also offers dating coaching and advice from the most advanced experts on the planet.",

    footer: "The Result? More Dates, Connection And Dream Relationships Than You Ever Imagined Possible. ",
    
}