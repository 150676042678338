export const ToStextfill = {
  date_effective: "(V6.0 – Effective July 1st, 2017)",
  foreword: "PLEASE READ THE FOLLOWING TERMS AND CONDITIONS RELATING TO YOUR USE OF THIS SITE AND THE PRODUCTS AND SERVICES PROVIDED HEREUNDER CAREFULLY. By i) using this site ii) purchasing any products or services from Mehow Publishing, Inc. ( “Mehow” or “we”) DBA as “Mr.Right” and “Positive Relating”, iii) or utilizing any of the products or services from Mehow through this site, you are deemed to have agreed to these terms and conditions. We reserve the right to modify them at any time. You should check these terms and conditions periodically for changes. By using this site after we post any changes to these terms and conditions, you agree to accept those changes, whether or not you have reviewed them. With regard to products and services purchased or utilized from Mehow, the version of these terms and conditions that were posted at the time of purchase or utilization apply. If at any time you choose not to accept these terms and conditions of use, please do not use this site.",
  linebreak: "******************************************************************************",


  part_Ia: "These terms and conditions apply to your use of all of the website at mrright.net, all websites owned by Mehow Publishing Inc. including but not limited to: mrright.net, threesecondattraction.com (collectively the \"Site\"), as well as to products and services purchased or utilized from the Site. Unless stated otherwise, all references to the Site in these terms and conditions include this site. These terms and conditions do not apply to your use of unaffiliated sites to which the Site only links. ",
  part_Ibf0: "The contents of this site are protected by copyright and trademark laws, and are the property of their owners. Unless we say otherwise, you may access the materials located within the Site only for your personal use. This means you may download one copy of posted materials on a single computer for personal, noncommercial home use only, so long as you neither change nor delete any author attribution, trademark, legend or copyright notice. When you download copyrighted material you do not obtain any ownership rights in that material.",
  part_Ibf1: "You may not modify, copy, publish, display, transmit, adapt or in any way exploit the content of the Site. Only if you obtain prior written consent from us and from all other entities with an interest in the relevant intellectual property may you publish, display or commercially exploit any material from the Site.",
  part_Ibf2: "You must abide by all additional copyright notices or other restrictions contained in any of the Site.",
  part_Ibf3: "You agree not to do any of the following while using the Site:",
  part_Ib1: "1) harass, stalk or otherwise abuse another user;",
  part_Ib2: "2) transmit or otherwise make available any content that is false, harmful, threatening, abusive, tortious, defamatory, libelous, disparaging (including disparaging of the Site), vulgar, obscene, pornographic or that promotes violence, racial hatred, terrorism or illegal acts, or is otherwise objectionable (as determined by us in our sole discretion);",
  part_Ib3: "3) transmit or otherwise make available any content that is unlawful or infringes, violates or misappropriates any patent, trademark, trade identity right, trade secret, publicity right, privacy right, copyright or any other intellectual property or any other rights of any third party;",
  part_Ib4: "4) upload or transmit viruses, Trojan horses or other harmful, disruptive or destructive files or post material that interferes with any third party’s uninterrupted use and enjoyment of the Site.",
  part_Ib5: "5) impersonate any person or entity, or otherwise disguise the origin of any content transmitted through the Site or to us, including forging any TCP/IP packet header or any part of the header information in any transmission to the Site for any reason;",
  part_Ib6: "6) transmit or otherwise make available through the Site any personal advertising, junk mail, spam, chain letters, pyramid schemes or offer for sale of any products or services, except in areas specifically designated for such purposes; or",
  part_Ib7: "7) violate any applicable local, state, federal or international law, rule or regulation.",
  part_Ic0: "These terms and conditions apply only to this Site, and not to the sites of any other companies or organizations, including those to which this Site may link. We are not responsible for the availability of any other site to which this Site links. We do not endorse or take responsibility for the contents, advertising, products or other materials made available through any other site. Under no circumstances will we be held responsible or liable, directly or indirectly, for any loss or damage that is caused or alleged to have been caused to you in connection with your use of, or reliance on, any content, goods or services available on any other site. You should direct any concerns to that site administrator or webmaster.",
  part_Ic1: "Other sites may link to this Site only through a plain-text link or provided graphics link. Permission must be granted by us for any other type of link to the Site. To seek our permission, you may send E – mail to support@mrright.net. We reserve the right, however, to rescind any permission granted by us to link through a plain-text link or any other type of link, and to require termination of any such link this Site, at our discretion at any time.",
  part_Ic2: "THE SERVICES, PRODUCTS AND MATERIALS ON OR FROM THIS SITE ARE PROVIDED \"AS IS\" AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. WE DISCLAIM ALL WARRANTIES TO THE MAXIMUM EXTENT OF THE LAW, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.",
  part_Ic3: "NEITHER WE NOR ANY OF OUR RESPECTIVE LICENSORS OR SUPPLIERS WARRANT THAT ANY FUNCTIONS CONTAINED IN THE SITE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SITE OR THE SERVER THAT MAKES THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.",
  part_Ic4: "NEITHER WE NOR ANY OF OUR RESPECTIVE LICENSORS OR SUPPLIERS WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE SERVICES, PRODUCTS OR MATERIALS IN THIS SITE IN TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY, OR OTHERWISE. YOU (AND NOT WE OR ANY OF OUR RESPECTIVE LICENSORS OR SUPPLIERS) ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION TO YOUR SYSTEM.",
  part_Ic5: "APPLICABLE LAW MAY NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU. TO THE EXTENT ANY WARRANTY CANNOT BE DISCLAIMED, A LIMITED EXPLICT WARRANTY IS GIVEN BY US TO THE EXCLUSION OF ANY OTHER REMEDY TO, IN OUR DISCRETION, EITHER A) REPLACE THE SERVICES, PRODUCTS AND MATERIALS WITH SUCH SERVICES, PRODUCTS AND MATERIALS THAT DO NOT BREACH A WARRANTY IF SUCH BREACH IS IDENTIFIED TO US WITHIN 30 DAYS OF PURCHASE OR B) REFUND OF THE MONIES PAID FOR THE PARTICULAR SERVICES, PRODUCTS AND MATERIALS.",
  part_Ic6: "BY ACCESSING THIS SITE, YOU UNDERSTAND THAT YOU MAY BE WAIVING RIGHTS WITH RESPECT TO CLAIMS THAT ARE AT THIS TIME UNKNOWN OR UNSUSPECTED, AND IN ACCORDANCE WITH SUCH WAIVER, YOU ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND, AND HEREBY EXPRESSLY WAIVE, THE BENEFITS OF SECTION 1542 OF THE CIVIL CODE OF CALIFORNIA, AND ANY SIMILAR LAW OF ANY STATE OR TERRITORY, WHICH PROVIDES AS FOLLOWS: \"A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.\"",
  part_Ic7: "We do not endorse, warrant or guarantee any products or services offered on the Site. We are not a party to, and do not monitor, any transactions between users and third party providers of products or services.",
  part_Id: "UNDER NO CIRCUMSTANCES, INCLUDING BUT NOT LIMITED TO NEGLIGENCE, WILL WE OR ANY OF OUR LICENSORS OR SUPPLIERS BE LIABLE FOR ANY SPECIAL OR CONSEQUENTIAL DAMAGES THAT RESULT FROM THE USE OF, OR THE INABILITY TO USE, THE MATERIALS ON THE SITE, OR ANY PRODUCTS OR SERVICES PROVIDED PURSUANT TO THE SITE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IN NO EVENT SHALL THE TOTAL LIABILITY TO YOU BY US OR ANY OF OUR LICENSORS OR SUPPLIERS FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT, TORT, OR OTHERWISE) EXCEED THE AMOUNT PAID BY YOU TO US, IF ANY, FOR ACCESSING THE SITE.",
  part_Ie: "The information contained in or made available through this Site (including but not limited to information contained on message boards, in text files, in products, from services, or in chats) cannot replace or substitute for the services of trained professionals in any field, including, but not limited to, psychological, financial, medical, or legal matters. In particular, you should regularly consult a doctor in all matters relating to physical or mental health, particularly concerning any symptoms that may require diagnosis or medical attention. Further, you should regularly consult a lawyer in all matters relating to interacting with other people to assure yourself you are behaving in compliance with law, including but not limited to laws related to harassment, assault or other similar laws. We and our licensors or suppliers make no representations or warranties concerning any treatment, action, or application of medication or preparation by any person following the information offered or provided within or through the Site (including but not limited to any product or service purchased, utilized or otherwise obtained from this Site). Neither we nor our partners, or any of their affiliates, will be liable for any direct, indirect, consequential, special, exemplary or other damages that may result, including but not limited to economic loss, injury, illness or death.",
  part_If0: "Some of the information available on the Site (including but not limited to any product or service purchased, utilized or otherwise obtained from this Site) concerns the topic of sex and may not be appropriate for minors. Further, the Site (as well as any product or service purchased, utilized or otherwise obtained from this Site) is not directed to persons under the age of 18 and we will not knowingly collect personally identifiable information from persons under 18. ",
  part_If1: "WE STRONGLY RECOMMEND THAT PARENTS PARTICIPATE IN THEIR CHILDREN EXPLORATION OF THE INTERNET AND ANY ONLINE SERVICES AND USE THEIR BROWSERS PARENTAL CONTROLS TO LIMIT THE AREAS OF THE INTERNET TO WHICH THEIR CHILDREN HAVE ACCESS.",
  part_If2: "We hereby require all users of the Site or purchasers of Mehow’s products and services to be over 18. You agree to abide by any such restrictions, and not to help anyone avoid these restrictions. If you are under 18, you agree to immediately stop accessing the Site or using any of the products or services of Mehow. If you are accessing the Site or using or purchasing any of the products or services of Mehow, you represent that you are at least 18 years of age.",
  part_Ig: "We reserve the right to charge fees, surcharges and/or membership fees for all or any of its services currently provided for free at any time upon thirty (30) days’ prior written notice to you.",
  part_Ih: "All content included on this Site, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of Mehow, Inc or its content suppliers and protected by United States and international copyright laws. The compilation of all content on this site is the exclusive property of Mehow, Inc and protected by U.S. and international copyright laws. All software used on this site is the property of Mehow, Inc or its software suppliers and protected by United States and international copyright laws.",
  part_Ii: "Positive Relating, the stylized logo, and other Mehow, Inc logos, page headers, button icons, scripts, and service names are trademarks, registered trademarks or trade dress of Mehow Publishing, Inc. or its affiliates in the U.S. and/or other countries. Mehow, Inc’s trademarks and trade dress may not be used in connection with any product or service that is not Mehow, Inc’s, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits Mehow Publishing, Inc. All other trademarks not owned by Mehow, Inc or its affiliates that appear on this site are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by Mehow, Inc or its affiliates. Hypnotica is a trademark of Hypnotica LLC.",
  part_Ij: "You agree that you will not make any disparaging statements concerning Mehow, Inc, any of its affiliates or subsidiaries, officers, directors, employees or agents, except as may be required by law. ",


  part_IIa0: "General: As a service to our users, this Site may feature message boards, chat rooms/areas, discussion forums, ratings, comments, bulletin board services, news groups (including, without limitation, Usenet and other third party news groups), communities and/or other message or communication facilities and other public/semi-public/private forums (collectively, \"Forums\") where users with similar interests or similar experiences can share information and support one another or where users can post questions for others to answer. We may also offer online discussions moderated by various experts or other persons.",
  part_IIa1: "Much of the content of the Forums, including without limitation the descriptions for many Forums and the content within a specific message, comment or posting, is provided by and is the responsibility of the third party creator of the Forum or the person posting in that Forum. Mehow Publishing, Inc. (\"Mehow\") has no responsibility for such content and is merely providing access to such content as a service to you.",
  part_IIa2: "BY THEIR VERY NATURE, FORUMS MAY CARRY OFFENSIVE, HARMFUL, INACCURATE OR OTHERWISE INAPPROPRIATE MATERIAL, OR IN SOME CASES, POSTINGS THAT HAVE BEEN MISLABELED OR ARE OTHERWISE DECEPTIVE. WE EXPECT THAT YOU WILL USE CAUTION AND COMMON SENSE AND EXERCISE PROPER JUDGMENT WHEN USING DISCUSSION FORUMS.",
  part_IIb: "Any information shared (including personally identifiable information or other personal information) that you reveal in a Forum is by design open to the public (or to a private group within a special Forum) and is not a private, secure service. You should think carefully before disclosing any information in any Forum. What you have written may be seen, disclosed to or collected by third parties and may potentially be used by others in ways we are unable to control or predict, including to contact you for unauthorized purposes. By submitting communications or content to Forums, you agree that such submission is non-confidential for all purposes.",
  part_IIc: "You agree that you will not upload or transmit any communications or content of any type to a Forum that infringe or violate any rights of any party. Further, you may have entered into an agreement with Mehow that requires you to maintain the confidentiality of certain material or information of Mehow. It is your obligation to confirm that any post to a Forum you make does not breach any confidentiality obligation you have. Unless a Forum specifically notes that all members of the Forum who are able to view posts are bound by confidentiality obligations, and further notes what types of information may be discussed, you may not post information which Mehow has required you to preserve as confidential.",
  part_IId: "To the extent you are the original copyright holder of any post or submission by you to a Forum and such post or submission does not contain any of the information or material of Mehow or other information you are required to preserve as confidential by Mehow, then; i) you remain owner of such post or submission to the extent you were the owner; ii) you automatically grant on behalf of yourself or otherwise warrant that the owner of such content or intellectual property has expressly granted Mehow, a royalty-free, perpetual, irrevocable, world-wide nonexclusive license to use, reproduce, create derivative works from, modify, publish, edit, translate, distribute, perform, display, and/or otherwise exploit, the post, submission, communication or content in any media or medium, or any form, format, or forum now known or hereafter developed; and iii) you agree and warrant that Mehow may sublicense or assign its rights through multiple tiers of sublicenses or assigns.",
  part_IIe: "Mehow does not control the information delivered to the Forums, and has no obligation to monitor the Forums. However, Mehow reserves the right at all times to disclose any information as necessary to satisfy any applicable law, regulation, legal process or legal governmental request, or to edit, refuse to post or to remove any information or materials, in whole or in part, for any reason whatsoever, in Mehow’s sole discretion.",
  part_IIf: "Mehow is not obligated to remove any content from the site which does not violate any civil or criminal laws and any contributions intended for display on this web site via any means, whether submitted via HTTP (web), SMTP (mail), NNTP (newsreader), or otherwise, are presumed to be contributed by the author/contributor with the intent that Mehow shall have an ongoing non-exclusive right to publish the contributed content for an indefinite amount of time, unless the content submitted was made under an agreement with Mehow, Inc with differing term agreements or Mehow has clearly only been provided limited non-exclusive publishing rights (for example, in the case of some exclusively copyrighted newsletter content). If you do not wish to have something which you submit to be published, do not submit it in the first place.",
  part_IIg: "Mehow does not endorse, support, represent or guaranty the truthfulness, accuracy, or reliability of any communications posted in the Forums or endorse any opinions expressed in the Forums. You acknowledge that any reliance on material posted in the Forums will be at your own risk.",
  part_IIh: "If Mehow discovers communications which allegedly do not conform to any terms and conditions of this Site, Mehow may investigate the allegation and determine in good faith and in its sole discretion whether to remove or request the removal of the communication. Mehow will have no liability or responsibility for performance or non-performance of such activities. Mehow reserves the right to terminate or restrict your access to any or all of the Forums at any time without notice for any reason whatsoever. You acknowledge that some Forums available through the Site are available only through the Site and others are available both through the Site and other sources, such as Usenet, over which Mehow has absolutely no control.",


  part_IIIa0: "Products shipped by Mehow Publishing, (\"Mehow\") may be shipped by UPS Standard or by any other method in the discretion of Mehow. A shipping charge will be imposed.",
  part_IIIa1: "1) Orders shipped to PR will have a 6.6% duty tax.",
  part_IIIa2: "2) All orders require at least 24-48 hours processing time before shipping.",
  part_IIIa3: "3) Mehow does not process orders during weekends.",
  part_IIIa4: "4) No deliveries will be made on Saturday or Sunday.",
  part_IIIa5: "5) Mehow does not guarantee same day shipping.",
  part_IIIa6: "6) All orders shipped within CA will be charged sales tax.",
  part_IIIa7: "7) Exact delivery times to any location cannot be guaranteed.",
  part_IIIb: "Sales tax is only required for orders shipping within our resident states of business. Therefore all orders shipping within California will be charged applicable sales tax according to your area’s tax rate.",
  part_IIIc: "Orders shipped to PR will have a 6.6% duty tax applied. Exact delivery times to PR cannot be guaranteed.",
  part_IIId: "Mehow strives for accuracy in all item descriptions, photographs, compatibility references, detailed specifications, pricing, links and any other product-related information contained herein or referenced on our website. Due to human error and other determinates we cannot guarantee that all item descriptions, photographs, compatibility references, detailed specifications, pricing, links and any other product-related information listed is entirely accurate, complete or current, nor can we assume responsibility for these errors. In the event a product listed on our website is labeled with an incorrect price due to some typographical, informational, technical or other error, Mehow shall at its sole discretion have the right to refuse and/or cancel any order for said product and immediately amend, correct and/or remove the inaccurate information. Additionally, all hyperlinks to other websites from Mehow are provided as resources to customers looking for additional information and/or professional opinion. Mehow does not assume responsibility for the claims and/or representations made on these or any other websites.",
  part_IIIe: "Mehow is not responsible for changes or variations in product specifications and/or physical appearance, since in some cases Mehow acts as a distributor for others. In the interest of our customers, Mehow puts forth its best efforts to ensure that all product information is up-to-date and factual. Unfortunately there are varying determinates which, although infrequent, could cause the information on our website to become outdated without our immediate knowledge. This includes but is not limited to new versions or revisions, color deviations, retail package alterations and other variations that may be considered inconsequential by the manufacturer. In some cases, Mehow relies on the manufacturer of a product to communicate these differences. Presently we have no way of alerting customers prior to purchase in the event the manufacturer fails to do so. Consequently, Mehow will not be held responsible for product revision changes.",


  part_IV0: "By accepting delivery of any product delivered from Mehow Publishing, Inc. (\"Mehow\"), viewing such products, or otherwise using such products, you (\"Customer\") agree to be bound by the terms and conditions listed below. You and Mehow agree that the following terms and conditions are the exclusive terms governing the sales between Customer and Mehow. Any attempt to alter, supplement, modify or amend these terms and conditions by the Customer will be considered a material alteration of this agreement and, therefore, are null and void. In addition, these terms and conditions are subject to change at any time, without prior written notice. Therefore, please check these terms and conditions carefully each time you place an order with or accept delivery of any goods from Mehow.",
  part_IVa0: "Mehow offers our customers a 45-day (from the date of purchase) satisfaction replacement or refund guarantee on all product purchases where the \"100% Mehow Money Back Guarantee\" is advertised. It does not otherwise offer refunds or replacements pursuant to this Section.",
  part_IVa1: "To take advantage of Mehow’s satisfaction guarantee, the Customer must comply with the Product Return Procedure detailed below. Any breach of Mehow’s Return Policy Procedure will result in the loss of Mehow’s refund/replacement guarantee. Furthermore, all return shipments not in compliance with these instructions will be rejected.",
  part_IVa2: "Notwithstanding the foregoing, there are no refunds for any product subscriptions that are less than $100 per month, but the subscription can be canceled at anytime. For product subscriptions that are $100 or more per month, refunds are limited to the most recent single payment.",
  part_IVa3: "Notwithstanding the foregoing, there are no refunds for any products that were provided for shipping and handling cost only (such as the \"How to Talk 2 Hot Women\" product).",
  part_IVa4: "For products, please be aware that authorized refunds may take 4 to 6 weeks to process from i) in the case of the return of physical products, the date that Mehow receive the product or ii) in the case of products delivered by non-physical means, from the date of RMA approval.",
  part_IVb: "In the event that you, the Customer, has previously returned 1 or more prior product purchases, notwithstanding anything to the contrary, Mehow will only provide a replacement, not a refund, pursuant to its 45 day satisfaction guarantee, but only in the event the product media is defective.",
  part_IVc0: "To return a product in compliance with Mehow’s 45 day satisfaction guarantee, you must first email to support@mrright.net and request an RMA number. You must also include the name of the original purchaser, where the refund should be sent to, and a copy of your purchase receipt that you originally received from Mehow. Provided that you are eligible for a refund, Mehow shall provide you, by email, shipping instructions for returns with the RMA number. If return of a product is requested by Mehow, return of that product is a condition for a refund and will only be valid if they are mailed in according with the RMA directions (mailing to any other address except as set forth in the RMA instructions will not be honored as a return). Customer is solely responsible for shipping any returned product to Mehow. Customer agrees to use only reputable carriers capable of providing proof of delivery and insurance for the entire value of the shipment. Customer agrees to bear all shipping and insurance charges and all risk of loss for the return product during shipment. Customer agrees that all returned products will be 100% complete, in re-saleable condition, and will include the original packaging material, manuals, blank warranty cards, and other accessories provided by Mehow. If any component of the returned product is missing, Mehow’s Return Procedure will be breached and Mehow may in its sole and absolute discretion reject the entire return or choose to impose additional charges against the customer for replacement of the missing component(s).",
  part_IVc1: "A return will not be processed until either i) it is received by you or ii) it has not be received by you and more than 30 days have passed since you made your purchase.",
  part_IVc2: "Mehow will not refund to Customer the original shipping charges.",
  part_IVd0: "The aforementioned 45-day satisfaction replacement or refund guarantee only applies to purchases of products and does not apply to the purchase of live event services provided by Mehow (“Mehow Live Event Services”). Mehow Live Event Services include but are not limited to bootcamps, seminars, personal instruction, and other live/in-person events.",
  part_IVd1: "Deposits for Mehow Live Event Services are only refundable if such refund is requested 30 days or more before the date the particular Mehow Live Event Service is scheduled to be provided. A request for a refund for Mehow Live Event Services must be made in writing by email to support@mrright.net. If such request is made less than 30 days before the date the particular Mehow Live Event Service is scheduled to be provided, the refund will be provided as a “credit” held by Mehow which may be used towards the purchase of any of the products offered by Mehow. Such credit will be valid for 180 days from the request of refund, and thereafter shall expire.",
  part_IVd_20: "If you interact with a verified user on the site and they aren’t “as described” in their profile please email support@mrright.net with their user name and what the nature of the issue is. For example, “he/she is not 5’10” more like 5’3”. We will contact the user via live video chat and verify the information and correct their profile. We will then pay you $100 if we deem, at our sole discretion, that the user’s profile was indeed inaccurate. If we cannot contact the “not as described user” for 2 weeks we will pay you $100 and ban the user until we can contact them. All verifications are done as part of our routine verification process and the user requesting verification is never identified nor do we specify that a complaint was made by anyone. Users which fail verification more then once may be removed permanently from the site at the companie’s sole discretion. LIMIT: one $100 payout per customer. To be eligible for the “not as described” guarantee there has to be an exchange of phone numbers via our messaging system on the site. We can deny payment of the guarantee to you if we determine fraud or foul play at our sole discretion.",
  part_IVd_21: "IF YOU INTERACT WITH A CLEARLY LABELED \"VERIFIED\" USER ON THE SITE AND THEY AREN’T “AS DESCRIBED” IN THEIR PROFILE PLEASE EMAIL SUPPORT@MRRIGHT.NET WITH THEIR USER NAME AND WHAT THE NATURE OF THE ISSUE IS.",
  part_IVe0: "MEHOW MAKES NO REPRESENTATION OR EXPRESS WARRANTY WITH RESPECT TO THE PRODUCT EXCEPT THOSE STATED IN THIS DOCUMENT. MEHOW DISCLAIMS ALL OTHER WARRANTIES, EXPRESS OR IMPLIED, AS TO ANY SUCH PRODUCT, INCLUDING AND WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY IMPLIED WARRANTIES ARISING FROM STATUTE, TRADE USAGE, COURSE OF DEALING, OR COURSE OF PERFORMANCE.",
  part_IVe1: "ALL ITEMS SOLD THROUGH OR BY MEHOW ARE SOLD \"AS-IS\" OR \"WITH ALL FAULTS.\" THE ENTIRE RISK AS TO THE QUALITY AND PERFORMANCE OF THESE ITEMS IS WITH THE BUYER. SHOULD ANY OF THESE ITEMS PROVE DEFECTIVE, DO NOT FUNCTION, OR FUNCTION IMPROPERLY IN ANY WAY FOLLOWING THEIR PURCHASE, THE BUYER, AND NOT MEHOW, ASSUMES THE ENTIRE COST OF ALL NECESSARY SERVICING OR REPAIR.",
  part_IVf0: "IN ALL CIRCUMSTANCES MEHOW’S MAXIMUM LIABILITY IS LIMITED TO THE PURCHASE PRICE OF THE PRODUCTS SOLD. MEHOW SHALL NOT, UNDER ANY CIRCUMSTANCES, BE LIABLE UPON A CLAIM OR ACTION IN CONTRACT, TORT, INDEMNITY OR CONTRIBUTION, OR OTHER CLAIMS RELATING TO THE PRODUCTS IT SELLS WHICH EXCEEDS THIS LIABILITY LIMIT. MEHOW SHALL NOT BE LIABLE FOR THIRD PARTY CLAIMS FOR DAMAGES AGAINST THE CUSTOMER, OR FOR MALFUNCTION, DELAYS, INTERRUPTION OF SERVICE, LOSS OF BUSINESS, LOSS OR DAMAGE TO EXEMPLARY DAMAGES, WHETHER OR NOT MEHOW HAS BEEN MADE AWARE OF THE POSSIBILITY OF SUCH CLAIMS OR DAMAGES. 4. CUSTOMER AGREED TO RESTRICTIONS, OBLIGATIONS AND LIMITATIONS Confidentiality and Limited Use:",
  part_IVf1: "Customer acknowledges that the products sold by Mehow are the confidential and proprietary information and property of Mehow. Customer hereby agrees to protect such products as confidential. As a further condition to the purchase and/or receipt of such products of Mehow by Customer, Customer agrees it will not, nor allow others, to directly or indirectly copy, distribute, resell, lend, lease, display, teach to others or show this product to others. A \"Customer\" includes anyone who receives the products of Mehow, even if for free. Customer agrees that no one may use this product in any manner without the written approval of Mehow, except for the Customer who has agreed that his/her use is limited to his/her own personal viewing. In the event Customer disagrees with these terms, Customer must immediately discontinue viewing the products purchased from Mehow and promptly return it to Mehow for refund according to (and limited by) the terms of this agreement. Anyone viewing or otherwise utilizing the products of Mehow by such conduct is agreeing to be bound by the terms of set forth herein, and as such must immediately comply with the terms of this agreement. In the event that Mehow discloses any portion of its information to the public, it should be understood that anything not publicly disclosed by Mehow remains information that you have an obligation to protect and maintain as confidential. Customer represents and warrants that he or she is at least 18 years old. In the event that Customer is under 18 years old, Customer will immediately discontinue viewing the products purchased from Mehow and promptly return it to Mehow for refund according to (and limited by) the terms of this agreement.",
  part_IVg0: " An order is not binding upon MEHOW until it is accepted; MEHOW must receive payment before it will accept an order. Payment for product(s) ordered is due prior to shipment. Customer can make payment by credit card, or some other method prearranged with MEHOW. You agree to pay the amount(s) due as specified on the invoice, and you agree to pay interest on all past-due sums at a rate of 1.5% per month or the highest rate allowed by law, whichever is greater.",
  part_IVg1: " Your total cost for purchase of any product will include shipping and handling charges shown on the MEHOW invoice.",
  part_IVg2: " MEHOW will arrange for shipment of ordered product(s) to the Customer, Free On Board (F.O.B.) shipping point, meaning title to the product(s) — excepting software– and risk of loss passes to the Customer upon delivery to the carrier. MEHOW reserves a purchase money security interest in the product(s) until its receipt of the full amount due. Customer agrees to allow MEHOW to sign appropriate documents on Customer’s behalf to permit MEHOW to protect its purchase money security interest. Title to software will remain with the licensor(s). All software is provided subject to the license agreement of the software maker. Customer agrees to be bound by any software license agreement once the seal on the package is broken. MEHOW will advise Customer of estimated shipping dates, but MEHOW will, under no circumstances, be responsible for delays in delivery, and associated damages, due to events beyond its reasonable control, including without limitation, acts of God or public enemy, acts of federal, state or local government, fire, floods, civil disobedience, strikes, lockouts, and freight embargoes.",
  part_IVg3: " Any dispute arising out of or related to these Terms and Conditions or the sales between MEHOW and Customer shall be governed by the laws of the State of California, without regard to its conflicts of law rules. Specifically, the validity, interpretation, and performance of this agreement shall not be governed by the United Nations Convention on the International Sale of Goods. Mehow and Customer consent to the exclusive jurisdiction and the exclusive venue of the State Courts of the State of California, San Diego County, to resolve any dispute between them related hereto, and the parities waive all rights to contest this exclusive jurisdiction and venue of such Courts. Finally, the Customer also agree not to bring any legal action, based upon any legal theory including contract, tort, equity or otherwise, against MEHOW that is more than one year after the date of the applicable invoice.",
  part_IVg4: " If any provision contained in this agreement is or becomes invalid, illegal, or unenforceable in whole or in part, such invalidity, illegality, or unenforceability shall not affect the remaining provisions and portions of this agreement, and the invalid, illegal, or unenforceable provision shall be deemed modified so as to have the most similar result that is valid and enforceable under applicable California law.",
  part_IVg5: " The failure of either party to require performance by the other party of any provision of this agreement shall not affect in any way the first party’s right to require such performance at any time thereafter. Any waiver by either party of a breach of any provision in this agreement shall not be taken or held by the other party to be a continuing waiver of that provision unless such waiver is made in writing",
  part_IVg6: " These terms and conditions, together with MEHOW’s invoice regarding the products ordered by Customer, are the complete and exclusive agreement between MEHOW and Customer, and they supersede all prior or contemporaneous proposals, oral or written, understandings, representations, conditions, warranties, and all other communications between MEHOW and Customer relating to the subject products. This agreement may not be explained or supplemented by any prior course of dealings or trade by custom or usage.",


  part_V0: "If you are a participant in any of the classes, seminars, phone coaching, forum coaching events and/or workshops or training via instructor via any medium(such collectively referred to as the “Event” or “Events”) of Mehow, this Part V. applies to you. You herby acknowledge and agree with the following:",
  part_Va: "a) All information and materials presented by, made in preparation of, provided in conjunction with or in relation to the Event(s), or the products of Mehow, or the materials and information provided pursuant to or in relation to the services provided by Mehow are collectively “Mehow Materials”. You agree that all Mehow Materials are for your personal use and personal entertainment purposes only. Any contribution, feedback or other material you provide for or during the Event(s), or based on or otherwise utilizing the Mehow Materials (“New Material”) shall be the property of Mehow and shall be treated as a work made for hire for Mehow. You agree to waive any rights or claims you may have to such New Materials and to convey ownership of such to Mehow as Mehow may request. You agree not to assert any “droit moral”, moral rights or other similar rights in the New Materials as requested by Mehow. New Material shall be considered Mehow Materials.",
  part_Vb0: "b) You agree that you will not, nor assist directly or indirectly others (whether by overt action or by failure to inform Mehow) in any manner record, to copy or make derivative works of any portion of the Mehow Materials or any portion of the Event(s).",
  part_Vb1: "1) In the event that you are discovered in violation of Section b). immediately above, you agree to immediately surrender any and all recording equipment to representatives of Mehow and you hereby waive any and all rights to recover such equipment, or any compensation for it. You understand and agree that your participation in any Event(s) or right to receive any Mehow Materials will be terminated without refund or compensation, but that you are still liable for the full applicable price of any such Event(s) or other rights terminated.",
  part_Vc: "c) You agree that any and all Mehow Materials, methodologies, concepts, or any related information, whether mailed, conversed, emailed, otherwise delivered and/or given in-hand, whether before, during and/or after the Event(s), are confidential and you agree you shall treat such as the confidential information and the property of Mehow and will not duplicate, email, forward, distribute, exploit, and/or otherwise used such without the express, prior written consent of Mehow. In preserving the confidentiality of the confidential information, such preservation shall include but is not limited to preventing disclosure to others and not allowing yourself to be filmed using the confidential information (for example demonstrating any methodology without written permission).",
  part_Vd: "d) You agree that the information contained in the Mehow Materials delivered and conveyed to you or given to you, whether before, during or after the Event(s) is proprietary and constitutes trade secrets which may not be provided to, divulged, disclosed or otherwise revealed to third parties, directly or indirectly, without the express prior written consent of Mehow.",
  part_Ve: "e) You agree that you will not directly or indirectly acquire any interest in, or design, create, manufacture, sell or otherwise deal with any item or product containing, based upon or derived in any way from the Mehow Materials and/or anything you learn, observe, or do while a participant in or in conjunction with the Event(s), including but not limited to any post Event consultation or communication/observation with Mehow or in your interactions with those representing Mehow and those identified as being representatives of Mehow.",
  part_Vf0: "f) Sections c), d), and e) of this Part V. shall be interpreted as covering the whole of the interactions at Mehow programs, and such are part of the Mehow Materials and/or Event(s). The \"Events\" also includes, but is not limited to:",
  part_Vf1: "1) Formal instructions by Mehow and its representatives",
  part_Vf2: "2) All interactions, whether observed or participated in, involving Mehow and its representatives",
  part_Vf3: "3) All interactions between any participants (students, instructors, observers) whether formal or otherwise, whether directly observed or otherwise, during an Event.",
  part_Vg: "g) You agree you will not reveal the names of the other attendees of the Event (such as attendees at Events of Mehow), or any identifying information regarding any such attendees to any third persons or parties.",
  part_Vh: "h) You represent that you are not a member of, an employee of, or an independent contractor to any news media, newspaper or magazine or other print publication or any other news organization, and/or news program, print or otherwise, or any radio station or other media related entity. You represent that you are not an employee of or an independent contractor to any television program or television production company. You agree that if you fall into any or all of these aforementioned categories, you will immediately identify yourself as such to Mehow, in writing, prior to the start of the Event.",
  part_Vi: "i) You agree that any dispute concerning the foregoing, if requested by Mehow, shall be submitted to binding arbitration before the Judicial Arbitration Mediation Service, in the State of California, applying California law, with the costs thereof to be equally paid by the parties to any such lawsuit during the pendency of the same, however the prevailing party shall be entitled to all costs and attorney fees. However, for the purposes of seeking an injunction, you, in addition, agree that Mehow may seek equitable relief (e.g. temporary restraining order or injunction) without the necessity of posting a bond, prior to, during, or after the conclusion of any arbitration and may seek a court order to enforce an arbitration award.",
  part_Vj: "j) You acknowledge that your involvement in any Event(s) is granted at the sole discretion of Mehow and your agreement to the terms specified in this agreement as well as any other terms which may be communicated, orally or in writing, by Mehow are required for and a condition of any such involvement by you.",
  part_Vk: "k) You agree that the spirit of participation in the Event(s) must be open-minded, positive, respectful, and constructive at all times, and you further agree that Mehow may, in its discretion, set rules and regulations that you agree to follow to further such purposes.",
  part_Vl: "l) You are to give your full and undivided attention and cooperation to Mehow throughout the Event(s). You further agree to perform all of the exercises given to you by Mehow throughout the duration of the Event(s).",
  part_Vm: "m) Mindful of the Event(s) curriculum and content and having had sufficient opportunity to ask any questions about the same, you represent and warrant that you are mentally fit and physically able to participate in the Event. You represent and warrant that you are at least 18 years old. You represent and warrant represent that any inaccurate statement or information provided by you in order to participate in the Event(s) has been corrected by you in writing and provided to Mehow prior to the receipt of the Mehow Materials or the start of any Event(s). As such, you further represent that you have made no material misrepresentations to Mehow.",
  part_Vn: "n) You further understand that Mehow does not provide any warranty or guarantee with respect to the degree or nature of any improvements achieved by me as a result of the Event. You certify unequivocally that it is your understanding and agreement that it is your responsibility, commitment and true investment of time and personal resources that will be the critical determinants of the degree and nature of the results you achieve.",
  part_Vo: "o) You agree that you are solely responsible for your actions and the consequences of your actions. You acknowledge and agree that Mehow and its representatives, assignees, etc., are not responsible for your potential misuse or inappropriate use of the Mehow Materials by you or by other participants in the Event(s). You agree to indemnify Mehow and its representatives, assignees, from any claims made against them due to any breach or alleged breach of this agreement or of any representation or warranty provided by me pursuant to this Agreement. You acknowledge and agree that Mehow does not teach or endorse any illegal activities or any action that could give rise to civil or criminal liability. In the case of any apparent conflict between the preceding sentence and Mehow Materials and Event(s), you will promptly inform Mehow of such with specificity and agree not to use such. You specifically agree not to violate law or infringe on the rights of any party using the Mehow Materials or other any aspect of the Event(s), including, but is not limited to, sexual assault and similar crimes. You agree that you waive any claim against Mehow, its representatives or assignees resulting from the actions or inactions of third parties.",
  part_Vp: "p) You AGREE THAT UNDER NO CIRCUMSTANCES WILL Mehow, his representatives or assignees BE LIABLE FOR ANY SPECIAL OR CONSEQUENTIAL DAMAGES THAT RESULT FROM THE USE OF, OR THE INABILITY TO USE, THE MEHOW MATERIALS or any aspect of the Event(s), EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY. IN NO EVENT SHALL THE TOTAL LIABILITY to you of Mehow, its representatives or assignees, FOR Any DAMAGES, LOSSES, AND/or CAUSES OF ACTION (WHETHER IN CONTRACT, TORT, OR OTHERWISE) EXCEED THE AMOUNT PAID BY you for the Event(s).",
  part_Vq0: "q) You agree to waive the provisions of Section 1542 of the Civil Code of the State of California, which provides as follows:",
  part_Vq1: "A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR. ",
  part_Vq2: "You understand that section 1542 gives you the right not to release existing claims of which you are not now aware, unless you voluntarily chose to waive this right. Having been so apprised, you nevertheless hereby voluntarily elect to, and do waive the rights described in section 1542, and elect to assume all risks for claims that now exist in your favor, known or unknown, from the subject of this Agreement. You acknowledge that you have had the opportunity to consult with legal counsel of my own choosing and to have the terms of this Agreement fully explained to you; that you are not executing this Agreement in reliance on any promises, representations or inducements other than those contained herein; and that you are still participating in the Events knowing that doing so binds you to the terms of this Part V. You agree that you do so voluntarily, free of any duress or coercion.",
  part_Vr: "r) Phone Coaching Only: We reserve the right to waive refunds and cease service if a failure to attend up to two or more scheduled appointments occurs without notification up to 12 hours before the scheduled call time.",


}