import React from "react";

import "./keith_M.scss";

export function Keith_M_Ad() { return (
  <div className="keith">

    <div className="keith-ad-image">
      <div className="keith-ad-button">
        <a href="/landerf"> Install Now </a>
      </div>
      <img src={require("./images/sampleFBmrrightwomens add.png")} alt="" className="keith_M-ad--img"/>
    </div>

  </div>
)}