import React from "react";
import "./termsPage.scss";
import { ToStextfill  } from  "../../content/textfills-ToS";
//====================================================================================================================
export function ToSPage() { return ( <div className="ToSmain">
  <br/><a href="https://mrright.net/">
    <img src={require("../../images/mrright-logo-horiz-black.png")} alt="" className="logo-black--img"/>
  </a>
  {/* ------------------------------- */}
  {/* need extra spaces? ( &nbsp; )  : https://www.computerhope.com/issues/ch001662.htm */}
  <h1>{"Terms of Service"}</h1>
  <h3>{"Terms and Conditions"}</h3>
  <h2>{ToStextfill.date_effective}</h2><br/>
  <div className="ToSmain--text">{ToStextfill.foreword}</div>
  {/* ============================== */}
  <br/>
  <div className="ToSmain--indexlinks">
    <h3>{"Index:"}</h3>
    <header><h4>{"Part I. "}</h4>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <a href="#part-I">{"General Terms & Conditions"}</a>
    </header>
    <header><h4>{"Part II. "}</h4>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <a href="#part-II">{"User Public Forum Submission/Participation Policy And Terms"}</a>
    </header>
    <header><h4>{"Part III. "}</h4>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <a href="#part-III">{"Special Notes With Regard To Product Purchases"}</a>
    </header>
    <header><h4>{"Part IV. "}</h4>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <a href="#part-IV">{"Product Purchase Agreement"}</a>
    </header>
    <header><h4>{"Part IV. d2)"}</h4>
      &nbsp;<a href="#part-IVd2">{"The Mr.Right “He or She is Not As Described” Guarantee"}</a>
    </header>
    <header><h4>{"Part V. "}</h4>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <a href="#part-V">{"Terms Applicable To Students"}</a>
    </header>
  </div>
  <br/>
  <div className="ToSmain--linebreak"><hr/></div>
  {/* ============================== */}
  <br/><br/>
  <h2 id="part-I">{"Part I. GENERAL TERMS & CONDITIONS"}</h2>
  <h3>{"a) Scope of Terms and Conditions"}</h3>
  <div className="ToSmain--text">
    {ToStextfill.part_Ia}
  </div>
  <h3>{"b) Restrictions on Use"}</h3>
  <div className="ToSmain--text">
      {ToStextfill.part_Ibf0}<br/><br/>
      {ToStextfill.part_Ibf1}<br/><br/>
      {ToStextfill.part_Ibf2}<br/><br/>
      {ToStextfill.part_Ibf3}<br/><br/>
      <div className="ToSmain--text-sub">
        {ToStextfill.part_Ib1}<br/><br/>
        {ToStextfill.part_Ib2}<br/><br/>
        {ToStextfill.part_Ib3}<br/><br/>
        {ToStextfill.part_Ib4}<br/><br/>
        {ToStextfill.part_Ib5}<br/><br/>
        {ToStextfill.part_Ib6}<br/><br/>
        {ToStextfill.part_Ib7}<br/><br/>
        {ToStextfill.part_Ib8}
      </div>
  </div>
  <h3>{"c) Links"}</h3>
  <div className="ToSmain--text">
    {ToStextfill.part_Ic0}<br/><br/>
    {ToStextfill.part_Ic1}<br/><br/>
    <div className="ToSmain--text-boldall">
      {ToStextfill.part_Ic2}<br/><br/>
      {ToStextfill.part_Ic3}<br/><br/>
      {ToStextfill.part_Ic4}<br/><br/>
      {ToStextfill.part_Ic5}<br/><br/>
      {ToStextfill.part_Ic6}<br/><br/>
    </div>
    {ToStextfill.part_Ic7}<br/><br/>
  </div>
  <h3>{"d) Limitation of Liability"}</h3>
  <div className="ToSmain--text-boldall">
    {ToStextfill.part_Id}
  </div>
  <h3>{"e) No Personal Advice"}</h3>
  <div className="ToSmain--text">
    {ToStextfill.part_Ie}
  </div>
  <h3>{"f) Parental Permission; Minimum Age 18 Requirement"}</h3>
  <div className="ToSmain--text">
    {ToStextfill.part_If0}<br/><br/>
    <div className="ToSmain--text-boldall">
      {ToStextfill.part_If1}<br/><br/>
    </div>
    {ToStextfill.part_If2}
  </div>
  <h3>{"g) MEMBERSHIP FEES"}</h3>
  <div className="ToSmain--text">
    {ToStextfill.part_Ig}
  </div>
  <h3>{"h) COPYRIGHT"}</h3>
  <div className="ToSmain--text">
    {ToStextfill.part_Ih}
  </div>
  <h3>{"i) TRADEMARKS"}</h3>
  <div className="ToSmain--text">
    {ToStextfill.part_Ii}
  </div>
  <h3>{"j) NON-DISPARAGEMENT"}</h3>
  <div className="ToSmain--text">
    {ToStextfill.part_Ij}
  </div>
  {/* ============================== */}
  <br/><br/>
  <h2 id="part-II">{"Part II. User Public Forum Submission/Participation Policy and Terms"}</h2>
  <h3>{"a) General"}</h3>
  <div className="ToSmain--text">
    {ToStextfill.part_IIa0}<br/><br/>
    {ToStextfill.part_IIa1}<br/><br/>
    <div className="ToSmain--text-boldall">
      {ToStextfill.part_IIa2}
    </div>
  </div>
  <h3>{"b) Your Participation/Contribution Requires Consideration"}</h3>
  <div className="ToSmain--text">
    {ToStextfill.part_IIb}
  </div>
  <h3>{"c) Confidential Obligations"}</h3>
  <div className="ToSmain--text">
    {ToStextfill.part_IIc}
  </div>
  <h3>{"d) Grant of Rights"}</h3>
  <div className="ToSmain--text">
    {ToStextfill.part_IId}
  </div>
  <h3>{"e) No Obligation to Monitor"}</h3>
  <div className="ToSmain--text">
    {ToStextfill.part_IIe}
  </div>
  <h3>{"f) No Obligation to Remove"}</h3>
  <div className="ToSmain--text">
    {ToStextfill.part_IIf}
  </div>
  <h3>{"g) No Endorsement"}</h3>
  <div className="ToSmain--text">
    {ToStextfill.part_IIg}
  </div>
  <h3>{"h) Company’s Right to Act"}</h3>
  <div className="ToSmain--text">
    {ToStextfill.part_IIh}
  </div>
  {/* ============================== */}
  <br/><br/>
  <h2 id="part-III">{"Part III. Special Notes with regard to Product Purchases"}</h2>
  <h3>{"a) Shipping Methods"}</h3>
  <div className="ToSmain--text">
    {ToStextfill.part_IIIa0}<br/><br/>
    <div className="ToSmain--text-sub">
      {ToStextfill.part_IIIa1}<br/><br/>
      {ToStextfill.part_IIIa2}<br/><br/>
      {ToStextfill.part_IIIa3}<br/><br/>
      {ToStextfill.part_IIIa4}<br/><br/>
      {ToStextfill.part_IIIa5}<br/><br/>
      {ToStextfill.part_IIIa6}<br/><br/>
      {ToStextfill.part_IIIa7}<br/><br/>
    </div>
  </div>
  <h3>{"b) Sales Tax"}</h3>
  <div className="ToSmain--text">
    {ToStextfill.part_IIIb}
  </div>
  <h3>{"c) Duty Tax"}</h3>
  <div className="ToSmain--text">
    {ToStextfill.part_IIIc}
  </div>
  <h3>{"d) Product listings"}</h3>
  <div className="ToSmain--text">
    {ToStextfill.part_IIId}
  </div>
  <h3>{"e) Product Revisions"}</h3>
  <div className="ToSmain--text">
    {ToStextfill.part_IIIe}
  </div>
  {/* ============================== */}
  <br/><br/>
  <h2 id="part-IV">{"Part IV. Product Purchase Agreements"}</h2>
  <div className="ToSmain--text">
    {ToStextfill.part_IV0}
  </div>
  <h3>{"a) Mehow SATISFACTION GUARANTEE; Other Refund Policies"}</h3>
  <div className="ToSmain--text">
    {ToStextfill.part_IVa0}<br/><br/>
    {ToStextfill.part_IVa1}<br/><br/>
    {ToStextfill.part_IVa2}<br/><br/>
    {ToStextfill.part_IVa3}<br/><br/>
    {ToStextfill.part_IVa4}
  </div>
  <h3>{"b) Return Abuse Policy"}</h3>
  <div className="ToSmain--text">
    {ToStextfill.part_IVb}
  </div>
  <h3>{"c) Product Return Procedure"}</h3>
  <div className="ToSmain--text">
    {ToStextfill.part_IVc0}<br/><br/>
    {ToStextfill.part_IVc1}<br/><br/>
    {ToStextfill.part_IVc2}
  </div>
  <h3>{"d) Service/Event Refunds"}</h3>
  <div className="ToSmain--text">
    {ToStextfill.part_IVd0}<br/><br/>
    {ToStextfill.part_IVd1}
  </div>
  <h3 id="part-IVd2">{"d2) The Mr.Right “He or She is Not As Described” Guarantee."}</h3>
  <div className="ToSmain--text">
    {ToStextfill.part_IVd_20}<br/><br/>
    <div className="ToSmain--text-boldall">
      {ToStextfill.part_IVd_21}
    </div>
  </div>
  <h3>{"e) WARRANTIES; ALL PRODUCTS SOLD \"AS-IS\" OR \"WITH ALL FAULTS\""}</h3>
  <div className="ToSmain--text-boldall">
    {ToStextfill.part_IVe0}<br/><br/>
    {ToStextfill.part_IVe1}
  </div>
  <h3>{"f) LIMITATION OF LIABILITY"}</h3>
  <div className="ToSmain--text">
    <div className="ToSmain--text-boldall">
      {ToStextfill.part_IVf0}<br/><br/>
    </div>
    {ToStextfill.part_IVf1}
  </div>
  <h3>{"g) GENERAL TERMS AND CONDITIONS"}</h3>
  <div className="ToSmain--text">
    <header><h4>{"1) Payment Terms; Orders:"}</h4>
      {ToStextfill.part_IVg0}
    </header><br/>
    <header><h4>{"2) Shipping Charges:"}</h4>
      {ToStextfill.part_IVg1}
    </header><br/>
    <header><h4>{"3) Title; Risk of Loss:"}</h4>
      {ToStextfill.part_IVg2}
    </header><br/>
    <header><h4>{"4) Governing Law and Jurisdiction:"}</h4>
      {ToStextfill.part_IVg3}
    </header><br/>
    <header><h4>{"5) Severability:"}</h4>
      {ToStextfill.part_IVg4}
    </header><br/>
    <header><h4>{"6) Waiver:"}</h4>
      {ToStextfill.part_IVg5}
    </header><br/>
    <header><h4>{"7) Entire Agreement:"}</h4>
      {ToStextfill.part_IVg6}
    </header>
  </div>
  {/* ============================== */}
  <br/><br/>
  <h2 id="part-V">{"Part V. Terms Applicable To Students"}</h2>
  <div className="ToSmain--text">
    {ToStextfill.part_V0}<br/><br/>
    {ToStextfill.part_Va}<br/><br/>
    {ToStextfill.part_Vb0}<br/><br/>
    {ToStextfill.part_Vb1}<br/><br/>
    {ToStextfill.part_Vc}<br/><br/>
    {ToStextfill.part_Vd}<br/><br/>
    {ToStextfill.part_Ve}<br/><br/>
    {ToStextfill.part_Vf0}<br/><br/>
    {ToStextfill.part_Vf1}<br/><br/>
    {ToStextfill.part_Vf2}<br/><br/>
    {ToStextfill.part_Vf3}<br/><br/>
    {ToStextfill.part_Vg}<br/><br/>
    {ToStextfill.part_Vh}<br/><br/>
    {ToStextfill.part_Vi}<br/><br/>
    {ToStextfill.part_Vj}<br/><br/>
    {ToStextfill.part_Vk}<br/><br/>
    {ToStextfill.part_Vl}<br/><br/>
    {ToStextfill.part_Vm}<br/><br/>
    {ToStextfill.part_Vn}<br/><br/>
    {ToStextfill.part_Vo}<br/><br/>
    {ToStextfill.part_Vp}<br/><br/>
    {ToStextfill.part_Vq0}<br/><br/>
    {ToStextfill.part_Vq1}<br/><br/>
    {ToStextfill.part_Vq2}<br/><br/>
    {ToStextfill.part_Vr}<br/><br/>
  </div>
  {/* ------------------------------- */}
</div>)} // End and close the render return.
//====================================================================================================================