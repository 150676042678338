export const Privacytextfill = {
  effective_date: "The EFFECTIVE DATE of this Privacy Policy is: July 1, 2017.",

  foreword: "The website at mrright.net shall hereafter be referred to collectively the “Web Site”.",
  foreword_00: " California law grants you certain rights to receive information about the third parties, if any, with whom this Web Site shares personally identifiable information for those third parties’ direct marketing purposes. As set forth below in this Web Site’s Privacy Policy, we comply with this law by not sharing your personally identifiable information with third parties for their direct marketing purposes unless you first affirmatively agree to the disclosure. For those California residents who want more information regarding our compliance with this law or your choices, please contact us at support@mrright.net. You must include this Web Site as the subject line, and your full name, e-mail address, and postal address in your message. We will only respond to messages specifically requesting information regarding our compliance with this law or your choices, and those that include the above information. We will also only respond to a request from you one time per calendar year.",
  foreword_01: "This Privacy Policy explains what information Mehow Publishing, Inc. (“Mehow” or “we”) DBA as “Mr.Right” and “Positive Relating” collects on the Web Site, how Mehow uses that information, your choices and this Web Site’s other privacy practices. This Privacy Policy only covers information collected at this Web Site, and does not cover any information collected at any other web site or offline by Mehow, any affiliated company or any other company (unless specifically stated). Please read this Privacy Policy carefully. In addition, please review this Web Site’s “Terms and Conditions” (linked at the bottom of this page), which governs your use of this Web Site.",


  usage_00: " We may ask you to provide us with certain personally identifiable information, which is information that could reasonably be used to identify you personally (such as your name, address, e-mail address and telephone number) when you register with us or at other times, such as when you buy products or services, if you choose to subscribe to a web forum or blog, or if you choose to participate in a contest, sweepstakes or other promotion. In addition, we may ask that you provide us with demographic information, information regarding your interests, or similar information when you submit a registration form on the Web Site. Providing us with information about yourself is voluntary, and you can always choose not to provide certain information, but then you may not be able to take advantage of some of the Web Site’s features and services.",
  usage_01: "The Web Site may also receive information about you from other sources, such as in connection with a promotion the Web Site is participating in that is offered on a third-party web site, and we may combine the information we receive from those other sources with information that we collect through this Web Site. In those cases, we will apply this Privacy Policy to any personally identifiable information received.",
  usage_02: "Mehow may use your personally identifiable information to provide you with requested information or services, to contact you in connection with a promotion, or for other purposes disclosed when you provide your information. We may use your information for internal business purposes, such as to improve the Web Site and our business strategies, or to serve specific content or advertisements to you. If you choose to receive information from the Web Site, we may contact you with information regarding one of our products or that of an affiliate or business partner. We may also contact you regarding any problems or questions we have relating to your use of the Web Site, or, in our discretion, notify you of changes to our Privacy Policy, Terms of Use or other policy or terms that affect you or your use of the Web Site.",
  usage_03: "If you submit to the Web Site a comment, photograph or other content to be published, online or offline (including on-air, in a DVD, or any other format), we may publish your name or other personally identifiable information in connection with publishing the content.",
  usage_04: "If you provide us with personally identifiable information of a friend, or your friend provides personally identifiable information about you, to e-mail a feature from the Web Site, the e-mail addresses you or your friend supply us for those activities will not be used to send you or your friend other e-mail communications unless disclosed at the time you provide the information.",
  usage_05: "Please note that information submitted to the Web Site via a “contact us,” “help” or other similar e-mail address or form will not necessarily receive a response. We will not use the information provided to these e-mail addresses or forms for marketing purposes unrelated to your request.",
  usage_06: " This Web Site is not directed to persons younger than 18, and does not knowingly collect any personally identifiable information from persons younger than 18. If you are younger than 18, do not send any information about yourself to the Web Site. If Mehow discovers that a person younger than 18 has provided the Web Site with personally identifiable information, Mehow will delete that person’s personally identifiable information.",
  usage_07: " When you visit or download information from this Web Site, our web servers may automatically collect web site usage information. Web site usage information is non-personally identifying information that describes how our visitors use the Web Site. It can include the number and frequency of visitors to each web page and the length of their stays, browser type, referrer data that identifies the web page visited prior and subsequent to visiting the Web Site, and IP addresses (see below for more information on IP addresses). We also may determine your screen resolution and the technology available in order to serve you the most appropriate version of a web page, e-mail or similar service.",
  usage_08: " An internet protocol or IP address is a number that is assigned to your computer or network when you are on the internet. When you request pages from this Web Site, our servers may log your IP address. Mehow may use IP addresses for a number of purposes, such as system administration, to generally determine your computer’s server location, to report aggregate information to our business partners or to audit use of the Web Site.",
  usage_09: " This Web Site’s pages or e-mail messages may contain cookies, web beacons (also known as clear gifs), or similar technologies as they become available. Cookies are information files that this Web Site may place on your computer to provide extended functionality. We may use cookies for a number of purposes, such as tracking usage patterns on the Web Site, measuring the effectiveness of advertising, limiting multiple responses and registrations, facilitating your ability to navigate the Web Site and as part of a verification or screening process. Most browsers are initially set up to accept cookies. You can reset your browser to refuse all cookies or to indicate when a cookie is being sent by indicating this in the preferences or options menu in your browser. However, it is possible that some parts of this Web Site will not operate correctly if you disable cookies and you may not be able to take advantage of some of this Web Site’s features. You should consult with your browser’s provider/manufacturer if you have any questions regarding disabling cookies.",
  usage_10: "A web beacon is a small graphic image that allows the party that set the web beacon to monitor and collect certain information about the viewer of the web page, web-based document or e-mail message, such as the type of browser requesting the web beacon, the IP address of the computer that the web beacon is sent to and the time the web beacon was viewed. Web beacons can be very small and invisible to the user, but, in general, any electronic image viewed as part of a web page or e-mail, including HTML based content, can act as a web beacon. Mehow may use web beacons to count visitors to the web pages on the Web Site or to monitor how our users navigate the Web Site, and we may include web beacons in e-mail messages in order to count how many messages sent were actually opened, acted upon or forwarded.",
  usage_11: "Our web pages may include advertisements for third parties and their products, and those third-party advertisements may include a cookie or web beacon served by the third party. Mehow does not have control over the cookies or web beacons used by third parties and does not have access to whatever information they may collect.",


  disclosure_00: "Mehow may disclose non-personally identifying, aggregated user statistics to third parties. Mehow does not share your personally identifiable information with third parties for their direct marketing purposes unless you first affirmatively agree to the disclosure. If you agree to receive communications from a third party, your information will be subject to the third party’s privacy policy. Therefore, if you later decide that you do not want that third party to use your information, you will need to contact the third party directly.",
  disclosure_01: "In addition, unless otherwise disclosed in this Privacy Policy or at the time you provide your information, Mehow will only share your personally identifiable information with third parties under the following limited circumstances:",
  disclosure_01a: " Mehow may employ other companies and individuals to perform functions on our behalf. Examples include, without limitation, hosting and administering this Web Site, fulfilling orders, sending communications on our behalf, analyzing data, and administering promotions. These third parties may have access to this Web Site’s user information, including personally identifiable information, in order to perform these functions on our behalf.",
  disclosure_01b: " Mehow may transfer and disclose information about our users, including personally identifiable information, and may use IP addresses to identify users in cooperation with internet service providers or law enforcement agencies, to comply with a legal obligation, at the request of governmental authorities conducting an investigation, to verify or enforce compliance with the policies governing the Web Site and applicable laws, or to protect the legal rights, interests, or safety of the Web Site, our users or others.",
  disclosure_01c: " Mehow reserves the right to disclose and transfer user information, including personally identifiable information, in connection with a corporate merger, consolidation, restructuring, a sale of assets, or other fundamental corporate change.",
  disclosure_01d: " If you choose to enter a sweepstakes, contest or other promotion, your personally identifiable information may be disclosed to third parties in connection with the administration of such promotion, including, without limitation, in connection with winner selection, prize fulfillment and as required by law, such as on a winners list. Also, by entering a promotion, you are agreeing to the official rules that govern that promotion, which may contain specific requirements of you, including, except where prohibited by law, allowing the sponsor(s) of the promotion to use your name, voice or likeness in advertising or marketing associated with the promotion.",

  
  userforms_00: "Please review our restrictions on use and guidelines for user forums in the Terms and Conditions (linked at the bottom of this page) for the Web Site. In addition, please note that this Privacy Policy does not apply to any information you may disclose publicly in user forums (such as message boards, online diaries, or similar web pages). Your user name will be used to identify you on the Web Site, including within user forums, so we recommend not using your real name or other information that can be used to identify you when selecting a user name. In addition, do not use the real name of another person as your user name. When you register, you will have the opportunity to create a public profile that will be available to others through the message boards, online diaries and other public web pages.",
  userforms_01: " You are solely responsible for your public profile’s content.",
  userforms_02: " Mehow bears no responsibility for any action or policies of any third parties who collect any information that users may disclose in user forums or other public areas of this Web Site.",

  security: "No data transmission over the internet or electronic storage of information can be guaranteed to be 100% secure. Please note that Mehow cannot ensure or warrant the security of any information you transmit to Mehow, and you do so at your own risk.",
  links: "The Web Site and communications sent by the Web Site may contain links that take you outside our services and are beyond our control. For example, you may encounter links from sponsors or partners, which may or may not include the Mehow logo as part of a co-branding agreement. If you “click” on the link to a third party, the “click” takes you to a third party’s web site or online content. These other web sites may set their own cookies, collect data, solicit personally identifiable information and/or have their own privacy policies.",
  transfering: "This Web Site is operated in the United States. If you are located in the European Union, Canada or elsewhere outside of the United States, please be aware that any information you provide to us will be transferred to the United States. By using the Web Site or providing us with your information, you consent to this transfer.",
  acceptance: "By using this Web Site, you accept our privacy practices, as outlined in this Privacy Policy. Mehow reserves the right to modify, alter or otherwise update this Privacy Policy at any time. We will post any new or revised policies on the Web Site. However, Mehow will use your personally identifiable information in a manner consistent with the Privacy Policy in effect at the time you submitted the information, unless you consent to the new or revised policy.",

}