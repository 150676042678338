import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router , Route, Switch } from 'react-router-dom'


import { GenLander } from './pages/general-lander/landergeneral';
import { FemaleLander } from './pages/female-lander/landerwoman';
import { MaleLander } from './pages/male-lander/landermen';
import { ToSPage } from './pages/legal-information/termsPage';
import { PrivacyPage } from './pages/legal-information/privacyPage';

import { Keith_M_Ad } from './pages/keith_M/keith_M';

import "./styles.css";

// https://github.com/ReactTraining/history
import { createBrowserHistory } from 'history';
const history = createBrowserHistory({
  basename: '', // The base URL of the app
});


// https://blog.logrocket.com/react-router-dom-set-up-essential-components-parameterized-routes-505dc93642f1/
const MyApp = () => (
    <Router className='App' basename='/' history={ history } >
      <Switch>
        <Route exact path="/"  component={ GenLander }/>

        <Route path="/lander/"  strict={false} component={ GenLander }/>
        <Route path="/landerm/" strict={false} component={ MaleLander }/>
        <Route path="/landerf/" strict={false} component={ FemaleLander }/>
        <Route path="/terms/"   strict={false} component={ ToSPage }/>
        <Route path="/privacy/" strict={false} component={ PrivacyPage }/>

        <Route path="/keith_M/" strict={false} component={ Keith_M_Ad }/>
        

        {/* 404 route redirect */}
        <Route component={ GenLander } />
      </Switch>
    </Router>
);


const rootElement = document.getElementById('root');
ReactDOM.render(<MyApp />, rootElement);


// Older versions of how history was passed threw router:

// https://codeburst.io/react-router-v4-unofficial-migration-guide-5a370b8905a
//import createHashHistory from 'history/createHashHistory';
//const history = createHashHistory();

// https://stackoverflow.com/questions/25086832/how-to-stop-in-browser-with-react-router
//import { browserHistory as history } from 'react-router'
// https://reacttraining.com/react-router/web/api/BrowserRouter