import React from "react";

import "./storebutton.scss";

export function Storebutton() {
	return (
		<div className="store-button tech--store-button ">
			<a href="https://apps.apple.com/us/app/mr-right-dating/id1163084481">
				<span className="store-button--logo"> </span>
				App Store
			</a>
		</div>
	);
}
