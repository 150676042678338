import React from "react";
import "./privacyPage.scss";
import { Privacytextfill  } from  "../../content/textfills-privacy";
//====================================================================================================================
export function PrivacyPage() { return ( <div className="privacymain">
  <br/><a href="https://mrright.net/">
    <img src={require("../../images/mrright-logo-horiz-black.png")} alt="" className="logo-black--img"/>
  </a><br/><br/>
  {/* ------------------------------- */}
  {/* need extra spaces? ( &nbsp; )  : https://www.computerhope.com/issues/ch001662.htm */}
  <h1>{"Privacy Policy"}</h1><br/>
  <div className="privacymain--text">
    {Privacytextfill.foreword}<br/><br/>
    {Privacytextfill.effective_date}
  </div><br/>
  {/* ============================== */}
  <div className="privacymain--text">
    <header><h4>{"Notice to California Residents:"}</h4>
      {Privacytextfill.foreword_00}
    </header><br/><br/>
  </div>
  <h2>{"Privacy Policy"}</h2><br/>
  <div className="privacymain--text">
    {Privacytextfill.foreword_01}<br/><br/>
  </div><br/>
  {/* ============================== */}
  <h2>{"INFORMATION COMPANY COLLECTS AND HOW COMPANY USES IT"}</h2><br/>
  <div className="privacymain--text">
    <header><h4>{"Personally Identifiable Information:"}</h4>
      {Privacytextfill.usage_00}<br/>
    </header><br/>
    {Privacytextfill.usage_01}<br/><br/>
    {Privacytextfill.usage_02}<br/><br/>
    {Privacytextfill.usage_03}<br/><br/>
    {Privacytextfill.usage_04}<br/><br/>
    {Privacytextfill.usage_05}<br/><br/>
    <header><h4>{"Note To Parents About Children:"}</h4>
      {Privacytextfill.usage_06}<br/>
    </header><br/>
    <header><h4>{"Non-Personally Identifiable Information:"}</h4>
      {Privacytextfill.usage_07}<br/>
    </header><br/>
    <header><h4>{"Use Of IP Addresses:"}</h4>
      {Privacytextfill.usage_08}<br/>
    </header><br/>
    <header><h4>{"Use of Cookies, Web Beacons and Similar Technologies:"}</h4>
      {Privacytextfill.usage_09}<br/>
    </header><br/>
    {Privacytextfill.usage_10}<br/><br/>
    {Privacytextfill.usage_11}<br/><br/>
  </div><br/>
  {/* ============================== */}
  <h2>{"DISCLOSURE OF COLLECTED INFORMATION & THIRD PARTIES"}</h2><br/>
  <div className="privacymain--text">
    {Privacytextfill.disclosure_00}<br/><br/>
    {Privacytextfill.disclosure_01}<br/><br/>
    <div className="privacymain--text-sub">
      <header><h4>{"Third parties providing services on our behalf:"}</h4>
        {Privacytextfill.disclosure_01a}<br/>
      </header><br/>
      <header><h4>{"Legal Protection:"}</h4>
        {Privacytextfill.disclosure_01b}<br/>
      </header><br/>
      <header><h4>{"Corporate change:"}</h4>
        {Privacytextfill.disclosure_01c}<br/>
      </header><br/>
      <header><h4>{"Sweepstakes, Contests and Promotions:"}</h4>
        {Privacytextfill.disclosure_01d}<br/>
      </header><br/>
    </div>
  </div><br/>
  {/* ============================== */}
  <h2>{"USER FORUMS"}</h2><br/>
  <div className="privacymain--text">
    {Privacytextfill.userforms_00}
    <b>{Privacytextfill.userforms_01}</b>
    {Privacytextfill.userforms_02}<br/><br/>
  </div><br/>
  {/* ============================== */}
  <h2>{"YOUR CHOICES"}</h2><br/>
  <div className="privacymain--text">
    <h3>{"SECURITY"}</h3><br/>
    {Privacytextfill.security}<br/><br/>
    <h3>{"LINKS"}</h3><br/>
    {Privacytextfill.links}<br/><br/>
    <h3>{"CONSENT TO TRANSFER"}</h3><br/>
    {Privacytextfill.transfering}<br/><br/>
    <h3>{"ACCEPTANCE & PRIVACY POLICY CHANGES"}</h3><br/>
    {Privacytextfill.acceptance}<br/><br/>
  </div>
  {/* ------------------------------- */}
</div>)} // End and close the render return.
//====================================================================================================================