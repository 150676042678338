import React from "react";

import "./landerwoman.scss";
import { Storebutton } from "./../../components/storebutton/storebutton";
import { FooterTech } from './../../pieces/footertech/footertech';
import { textfillswoman  } from  "./../../content/textfills-lander-woman";

export function FemaleLander() { return (

  <div className="woman">
    <div className="logo-white">
      <img src={require("./../../images/mrright-logo-horiz-white.png")} alt="" className="logo-white--img"/>
    </div>
    <div class="woman--format">
      <div class="woman--slogan">
        {textfillswoman.behis}
      </div>
      <div className="woman--slogan--store-button">
          <Storebutton />
        </div>
      <div className="woman--phone-hand">
        <img src={require("./../../images/phone_hand-02.png")} alt="" className="woman--phone-hand--img"/>
      </div>
    </div>

    <div className="woman-middle">
      <div className="woman-middle--slogan-header01">
        {textfillswoman.slogan_H01}
        <div className="woman-middle--slogan-sentences01">
          {textfillswoman.slogan_T01}
        </div>
        <img src={require("./../../images/female-circle.png")} alt="" className="woman--match-bubbles--img"/>
      </div>
      <div className="woman-middle--slogan-header02">
        {textfillswoman.slogan_H02}
        <div className="woman-middle--slogan-sentences02">
          {textfillswoman.slogan_T02}
        </div>
        <img src={require("./../../images/money-phone.png")} alt="" className="woman--money-phone--img"/>
      </div>
      
      <div className="woman-middle--download">
        Download it now!
        <div className="woman-middle--download--button">
          <Storebutton />
        </div>
      </div>
    </div>

    <div className="woman-bottom">
      <div className="woman-bottom--sales-pitch">
        {textfillswoman.salesPitch}
      </div>
      <div className="woman-bottom--sales-header01">
        {textfillswoman.pitch_H01}
        <div className="woman-bottom--sales-sentences01">
          {textfillswoman.pitch_T01}
        </div>
        <img src={require("./../../images/match-sample.png")} alt="" className="woman--match-sample--img"/>
      </div>
      <div className="woman-bottom--sales-header02">
        {textfillswoman.pitch_H02}
        <div className="woman-bottom--sales-sentences02">
          {textfillswoman.pitch_T02}
        </div>
      </div>
      <img src={require("./../../images/bottom-phone.png")} alt="" className="woman-bottom-phone--img"/>
    </div>

    <div className="woman-footer">
      <div className="woman-footer--storeinfo">
        {textfillswoman.footer}
        <div className="woman-footer--storeinfo--button">
          <Storebutton />
        </div>
      </div>
      <FooterTech />
    </div>
  </div>
      
)}