export const textfillswoman = {
    behis: "Matchmaking Technology that Upgrades Your Desirability",

    slogan_H01: "Verified Matches or We Pay YOU",
    slogan_T01: "Tired of guys who seem greaton paper—but then aren’t who they say they are? Mr.Right solves this problem by verifying a guy’s profile and pics BEFORE you ever exchange numbers or meet.",

    slogan_H02: "Earn $100 If He’s Not Who He Says",
    slogan_T02: "We’re so confident in our system for verifying our matches—in the highly unlikely event you ever meet a guy who’s been misrepresented, we’ll gladly pay you $100 for your trouble.",

    salesPitch: "Meet Great Guys Who Know How to Treat a Lady",

    pitch_H01: "Say Goodbye to Creeps and Weirdoes",
    pitch_T01: "The other big problem with most dating apps is the guys are all too often creepy or awkward. Mr. Right carefully screens and even coaches men to ensure they know how to properly talk and treat a lady like yourself.",

    pitch_H02: "Your Dream Guy Isn't Far Away",
    pitch_T02: "Mr. Right's advanced technology, verification, and coaching processes have lead to rapid growth. Which means it could very well help YOU find the perfect match, in your local area or city, and SOON.",

    footer: "The only way to find out is to join Mr. Right now.",
    
}