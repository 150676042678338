import React from 'react';

import "./footertech.scss";

export function FooterTech() {return (
  <div className="tos_p">

    <div className={["tos_p--links"]}>

      © 2018 Mr.Right, Inc
      &nbsp;&nbsp;&nbsp; {/* extra spaces: https://www.computerhope.com/issues/ch001662.htm */}
      <a href="https://www.mrright.net/privacy">Privacy Policy</a> 
      &nbsp;&nbsp;&nbsp;
      <a href="https://www.mrright.net/terms">Terms of Service</a>

    </div>

  </div>
)}